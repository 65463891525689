$('[data-behavior~=show_full_repository_description]').on('click', (event) => {
  var $link = $(event.currentTarget);
  var $description = $('[data-behavior~=repository_description]');

  if ($link[0].classList.contains('active')) {
    $description.html($description.attr('short-description'))
    $link.text($link.attr('default_content'));
    $link[0].classList.remove('active');
  } else {
    $description.html($description.attr('full-description'));
    $link.text($link.attr('active_content'));
    $link[0].classList.add('active');
  }
});

